import { styled } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { IoIosPhonePortrait } from 'react-icons/io';
import { AiOutlineMail } from 'react-icons/ai';
import { GiPositionMarker } from 'react-icons/gi';
import { AppTitle } from '../../Common/AppTitle';
import { AppTextInput } from '../../Common/AppTextInput';
import { ContactFormObj, emptyContactFormObj } from '../../Types/ContactFormObj';
import { isValidEmail } from '../../Helpers/Constants';
import { AppButton } from '../../Common/AppButton';
import { sendEmail } from '../../Helpers/Mailer';

export const Contact = () => {
  const { t } = useTranslation();
  const [showError, setShowError] = useState<boolean>(false);
  const [data, setData] = useState<ContactFormObj>(emptyContactFormObj);
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const submitEmail = async () => {
    setShowError(true);
    validateForm();
    if (formErrors.length) {
      return;
    }
    setIsSubmitting(true);
    try {
      const response: any = await sendEmail(data);
      if (response) {
        setShowError(false);
        setShowSuccess(true);
        setData(emptyContactFormObj);
      } else {
        setFormErrors(response.error.payload.toString());
      }
    } catch (error) {
      // Handle other errors
      setShowError(true);
      setFormErrors([t('contact.errors.contact-error')]);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (setShowSuccess) {
      setTimeout(() => {
        setShowSuccess(false);
      }, 5000);
    }
  }, [showSuccess]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        submitEmail();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [submitEmail]);

  useEffect(() => {
    validateForm();
  }, [data]);

  const validateForm = () => {
    const errors: string[] = [];
    if (!isValidEmail(data?.email)) {
      errors.push(t('contact.errors.invalid-email'));
    }
    if (
      data?.email?.trim().length === 0 ||
      data?.subject?.trim().length === 0 ||
      data?.message?.trim().length === 0 ||
      data?.name?.trim().length === 0
    ) {
      errors.push(t('contact.errors.required-field'));
    }
    setFormErrors(errors);
  };

  return (
    <ContactWrapper>
      <AppTitle text={t('contact.title')} />
      <div className="sides">
        <div className="info">
          <div className="info-row">
            <IoIosPhonePortrait />
            <p style={{ direction: 'ltr' }}>{t('contact.our-phone')}</p>
          </div>
          <div className="info-row green">
            <AiOutlineMail />
            <p>{t('contact.our-email')}</p>
          </div>
          <div className="info-row">
            <GiPositionMarker />
            <p>{t('contact.our-address')}</p>
          </div>
        </div>
        <div className="form">
          <div className="form-container">
            <AppTextInput
              showError={showError && !data.name}
              label={t('contact.full-name')}
              placeholder={t('contact.full-name-placeholder')}
              errorHint={t('contact.errors.required-field')}
              currentVal={data?.name}
              onChange={(newVal: string) => {
                setData((v) => ({ ...v, name: newVal }));
              }}
            />
            <AppTextInput
              showError={showError && !isValidEmail(data?.email)}
              label={t('contact.email')}
              placeholder={t('contact.email-placeholder')}
              errorHint={t('contact.errors.invalid-email')}
              currentVal={data?.email}
              onChange={(newVal: string) => {
                setData((v) => ({ ...v, email: newVal }));
              }}
            />
            <AppTextInput
              label={t('contact.phone')}
              placeholder={t('contact.phone-placeholder')}
              required={false}
              currentVal={data?.phone}
              onChange={(newVal: string) => {
                setData((v) => ({ ...v, phone: newVal }));
              }}
              forceLTR
            />
            <AppTextInput
              showError={showError && !data.subject}
              label={t('contact.object')}
              placeholder={t('contact.object-placeholder')}
              errorHint={t('contact.errors.required-field')}
              currentVal={data?.subject}
              onChange={(newVal: string) => {
                setData((v) => ({ ...v, subject: newVal }));
              }}
            />
            <AppTextInput
              showError={showError && !data.message}
              textArea
              label={t('contact.message')}
              placeholder={t('contact.message-placeholder')}
              errorHint={t('contact.errors.required-field')}
              currentVal={data?.message}
              onChange={(newVal: string) => {
                setData((v) => ({ ...v, message: newVal }));
              }}
            />
            {showSuccess && <p className="success">{t('contact.success')}</p>}
            <AppButton text={t('contact.button')} disabled={isSubmitting} action={submitEmail} />
          </div>
        </div>
      </div>
      <div className="map">
        <AppTitle text={t('contact.map-title')} type={2} />
        <p className="map-description">{t('contact.map-description')}</p>
      </div>

      <iframe
        title="contact-map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3313.1698236115253!2d-5.5779128!3d33.859514!2m3!1f0!2f0!3f0!3m2!1i1100!2i768!4f13.1!3m3!1m2!1s0xda05bc126d16eb9%3A0x24bdc0af198d3108!2sPixel%20Shades!5e0!3m2!1sen!2sfr!4v1693153944933!5m2!1sen!2sfr"
        style={{
          border: '0',
          width: '100%',
          minHeight: 400
        }}
        loading="lazy"
      />
    </ContactWrapper>
  );
};

const ContactWrapper = styled.div`
  width: 100%;
  margin-bottom: calc(-8px + (var(--v-margin) * -1));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .sides {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: var(--v-margin);
  }
  .info,
  .form {
    flex-shrink: 0;
  }
  .info {
    flex: 3;
    background: linear-gradient(rgba(23, 23, 23, 0.5), rgba(0, 0, 0, 0.5)),
      url(${require('../../Assets/Images/contact.webp')});
    background-position: bottom center;
    background-size: cover;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    .info-row {
      &.green {
        background-color: var(--dark-green);
      }
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 20px;
      background-color: var(--primary);
      padding: 20px;
      width: 600px;
      max-width: 95%;
      border-radius: 4px;
      * {
        padding: 0;
        margin: 0;
        color: var(--white);
        font-size: 20px;
      }
    }
    iframe {
      min-height: 400px;
    }
  }
  .form {
    flex: 2;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .form-container {
      width: 100%;
      max-width: 900px;
    }
    textarea {
      width: 100%;
      min-height: 100px;
      box-sizing: border-box;
      border: 1px solid grey;
      font-size: 20px;
      font-weight: 600;
      width: 100%;
      display: block;
      padding: 8px;
      margin: 0;
      margin-bottom: 20px;
      height: 2em;
      max-width: 100%;
      resize: vertical;
    }
    textarea::placeholder {
      color: var(--grey);
      font-weight: 400;
    }
  }
  .map-description {
    margin-top: -25px;
  }
  svg {
    flex-shrink: 0;
  }
  .success {
    color: var(--success);
    font-weight: 700;
    text-align: center;
  }
  @media only screen and (max-width: 1100px) {
    .sides {
      flex-direction: column;
    }
    .info {
      box-sizing: border-box;
      padding: var(--v-margin);
    }
    .form {
      box-sizing: border-box;
      width: 100%;
      padding: var(--v-margin);
    }
    .map {
      box-sizing: border-box;
      padding: var(--v-margin);
    }
  }
`;
