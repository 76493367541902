/* eslint-disable jsx-a11y/anchor-is-valid */
import { styled } from 'styled-components';
import { BiLogoFacebook, BiLogoInstagram, BiLogoLinkedin } from 'react-icons/bi';
import { facebookLink, instagramLink, linkedInLink } from '../Helpers/Constants';

export const Social = () => (
  <SocialWrapper>
    <ul>
      <li>
        <a href={facebookLink} target="_blank" rel="noreferrer" aria-label="facebook page" title="facebook page">
          <BiLogoFacebook />
        </a>
      </li>
      <li>
        <a href={instagramLink} target="_blank" rel="noreferrer" aria-label="instagram page" title="instagram page">
          <BiLogoInstagram />
        </a>
      </li>
      <li>
        <a href={linkedInLink} target="_blank" rel="noreferrer" aria-label="linkedin page" title="linkedin page">
          <BiLogoLinkedin />
        </a>
      </li>
    </ul>
  </SocialWrapper>
);

const SocialWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 120px;
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    overflow: hidden;
  }

  ul li {
    float: left;
    margin: 0 5px;
  }

  ul li a:hover {
    color: var(--dark-green);
  }

  ul li a {
    transition: all 300ms ease;
    display: block;
    color: var(--white);
    text-align: center;
    padding: 5px;
    text-decoration: none;
  }
`;
