import { styled } from 'styled-components';
import { ServicesSection } from './Components/ServicesSection';
import { SolutionSection } from './Components/SolutionSection';
import { VideoSection } from './Components/VideoSection';
import { HomeHero } from './Components/HomeHero';
import { PackagesSection } from './Components/PackagesSection';

export const Home = () => (
  <HomeWrapper>
    {/* <PackagesSection /> */}
    <HomeHero />
    <ServicesSection />
    <SolutionSection />
    <VideoSection />
  </HomeWrapper>
);

const HomeWrapper = styled.div`
  width: 100%;
  background-color: var(--white);
  margin: calc(var(--v-margin) * -1) 0;

  @media only screen and (max-width: 1100px) {
    margin: calc(var(--v-margin-mobile) * -1) 0;
  }
`;
