import { styled } from 'styled-components';
import { useState } from 'react';
import { Logo } from './Logo';
import { Social } from './Social';
import { Menu } from './Menu';
import { MenuIcon } from './MenuIcon';
import { MobileMenu } from './MobileMenu';
import { LanguageSwitcher } from './LanguageSwitcher';
import { headerHeight } from '../Helpers/Constants';

export const Header = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  return (
    <HeaderWrapper>
      <MenuIcon menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <div style={{ zIndex: menuOpen ? 3 : 1 }}>
        <Logo to="/" />
      </div>
      <div className="mobile-hidden">
        <Menu />
        <LanguageSwitcher />
        <Social />
      </div>
      <MobileMenu menuOpen={menuOpen} close={() => setMenuOpen(false)} />
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.header`
  height: ${headerHeight}px;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  padding: 5px var(--h-margin);
  background-color: var(--dark);

  .mobile-hidden {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;
  }
  @media only screen and (max-width: 1100px) {
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0;

    .mobile-hidden {
      display: none;
    }
  }
`;
