import { styled } from 'styled-components';

interface AnimatedColorTitleProps {
  text: string;
  fontSize?: number;
  colors?: string[];
}
export const AnimatedColorTitle = ({
  text,
  fontSize = 25,
  colors = ['#00ecb7', '#203672']
}: AnimatedColorTitleProps) => (
  <AnimatedColorTitleWrapper colors={colors} fontSize={fontSize}>
    <div>
      <h2 className="linear-wipe">{text}</h2>
    </div>
  </AnimatedColorTitleWrapper>
);

const AnimatedColorTitleWrapper = styled.div<{
  colors: string[];
  fontSize: number;
}>`
  display: block;
  h2 {
    font-weight: 400;
    ${({ fontSize }) => `font-size: ${fontSize}px;`}
  }

  .linear-wipe {
    ${({ colors }) =>
      `
    background: linear-gradient(
      to right,
      ${colors[0]} 1%,
      ${colors[1]} 20%,
      ${colors[0]} 30%,
      ${colors[1]} 50%,
      ${colors[0]}  70%,
      ${colors[1]}  80%,
      ${colors[0]}  99%
    );

    `}
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    /* text-fill-color: transparent; */
    background-size: 200% auto;
    animation: textShine 10s ease-in-out infinite alternate;
  }
  @keyframes textShine {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }
`;
