import { useTranslation } from 'react-i18next';
import { styled } from 'styled-components';

export const VideoSection = () => {
  const { t } = useTranslation();

  return (
    <VideoSectionWrapper>
      <div className="video-side">
        <video controls={false} autoPlay muted loop>
          <source src={require('../../../Assets/Videos/666.m4v')} type="video/mp4" />
        </video>
      </div>
      <div className="text-side">
        <h2>{t('home.video.title')}</h2>
        <p dangerouslySetInnerHTML={{ __html: t('home.video.description') }} />
      </div>
    </VideoSectionWrapper>
  );
};

const VideoSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  margin: 0;
  margin-top: 50px;
  .video-side {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    video {
      max-width: 100%;
    }
  }
  .text-side {
    padding: var(--v-margin);
    box-sizing: border-box;
    width: 50%;
    min-height: 400px;
    background: var(--dark-green);
    background: linear-gradient(321deg, var(--dark-green) 0%, var(--secondary) 100%);
    * {
      color: white;
    }
    h2 {
      font-size: 50px;
    }
    p {
      max-width: 700px;
    }
  }
  @media only screen and (max-width: 1100px) {
    flex-direction: column-reverse;
    video {
      margin-left: 0px;
    }
    .video-side {
      width: 100%;
      margin-bottom: -7px;
    }

    .text-side {
      height: auto;
      width: 100%;
      position: relative;
      color: white;
      width: auto;
      padding: 30px;
      h2 {
        font-size: 28px;
      }
      p {
        max-width: 400px;
        color: white;
      }
    }
  }
`;
